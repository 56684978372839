import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { categoriesHeader } from "./header/categoriesHeader";

// Building api for authentication
export const langApi = createApi({
  reducerPath: "langApi",
  baseQuery: fetchBaseQuery(categoriesHeader()),
  endpoints: (builder) => ({
    getAllLanguage: builder.query({
      query: () => ({
        url: "languages",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAllLanguageQuery,
} = langApi;
