import type { CustomFlowbiteTheme } from "flowbite-react";

const flowbiteTheme: CustomFlowbiteTheme = {
  badge: {
    root: {
      base: "bg-primary-100 text-primary-800 group-hover:bg-primary-200",
      size: {
        xl: "px-3 py-2 text-base rounded-md",
      },
    },
    icon: {
      off: "rounded-full px-2 py-1",
    },
  },
  // drawer: {
  //   header:{
  //     inner:{
  //       titleIcon:"hidden"
  //     }
  //   }
  // },
  // megaMenu: {
  //   dropdown: {
  //     base:"z-10 w-fit rounded !shadow-none !border-none block transition-opacity duration-100  bg-[#8CBC9E] text-gray-500 !absolute !left-[10px] !top-[8px]",
  //     toggle: {
  //       arrowIcon: "hidden"
  //     }
  //   }
  // },
  megaMenu: {
    root: {
      base: "z-10 fixed w-full bg-white lgPlus:py-0 py-[30px] lgPlus:px-[144px] px-[20px]",
      rounded: {
        on: "rounded",
        off: ""
      },
      bordered: {
        on: "border",
        off: ""
      },
      inner: {
        base: "mx-auto flex flex-wrap items-center justify-between",
        fluid: {
          on: "",
          off: "container"
        }
      }
    },
    brand: {
      base: "flex items-center"
    },
    collapse: {
      base: "w-full lgPlus:block lgPlus:w-auto",
      list: "flex gap-[16px] flex-col lgPlus:flex-row !text-[16px] leading-[30px] !text-gray-600 font-medium",
    },
    link: {
      base: "block !px-[10px] lgPlus:py-[24px] py-[10px]",
      disabled: {
        on: "text-gray-400",
        off: ""
      }
    },
    toggle: {
      base: "inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 lgPlus:hidden",
      icon: "h-6 w-6 shrink-0"
    },
    dropdown: {
      base: "",
      toggle: {
        arrowIcon: "hidden",
        content: "focus:outline-none",
        floating: {
          animation: "transition-opacity",
          arrow: {
            base: "absolute z-10 h-2 w-2 rotate-45",
            style: {
              light: "bg-white",
              auto: "bg-white "
            },
            placement: "-4px"
          },
          base: "z-10 w-fit divide-y divide-gray-100 rounded shadow focus:outline-none block",
          content: "py-1 text-sm text-gray-500",
          divider: "my-1 h-px bg-gray-100",
          header: "block px-4 py-2 text-sm",
          hidden: "invisible opacity-0",
          item: {
            container: "",
            base: "flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm hover:bg-gray-100 focus:bg-gray-100 focus:outline-none",
            icon: "mr-2 h-4 w-4"
          },
          style: {
            light: "border border-gray-200 bg-white text-gray-900",
            auto: "border border-gray-200 bg-white"
          },
          target: "w-fit"
        },
        inlineWrapper: "flex w-full items-center justify-between"
      }
    },
    dropdownToggle: {
      base: "py-2 pl-3 pr-4 lgPlus:p-0 border-b border-gray-100 lgPlus:border-0 flex w-full items-center justify-between"
    }
  },

  drawer: {
    root: {
      base: "fixed z-40 overflow-y-auto bg-white p-4 transition-transform ",
      backdrop: "fixed inset-0 z-30 bg-gray-900/50",
      edge: "bottom-16",
      position: {
        top: {
          on: "left-0 right-0 top-0 w-full transform-none",
          off: "left-0 right-0 top-0 w-full -translate-y-full"
        },
        right: {
          on: "right-0 top-0 h-screen w-80 transform-none",
          off: "right-0 top-0 h-screen w-80 translate-x-full"
        },
        bottom: {
          on: "bottom-0 left-0 right-0 w-full transform-none",
          off: "bottom-0 left-0 right-0 w-full translate-y-full"
        },
        left: {
          on: "left-0 top-0 h-screen w-80 transform-none",
          off: "left-0 top-0 h-screen w-80 -translate-x-full"
        }
      }
    },
    header: {
      inner: {
        closeButton: "absolute end-2.5 top-2.5 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
        closeIcon: "h-4 w-4",
        titleIcon: "hidden",
        titleText: "hidden"
      },
      collapsed: {
        on: "hidden",
        off: "block"
      }
    },
    // "items": {
    //   "base": ""
    // }
  },
  // progress:{
  //   bar:"w-overflow-hidden rounded-full bg-back h-[6px]",
  // },
  button: {
    base: "flex justify-center text-white text-[16px] leading-[24px] font-medium ",
    color:{
      info: "border border-transparent bg-cyan-700 text-white focus:ring-4 focus:ring-[#B2DDC2] enabled:hover:bg-cyan-800"
    }
  },
  dropdown: {
    floating: {
      base: "z-10 w-fit rounded-xl divide-y divide-gray-100 shadow",
      content: "rounded-xl text-sm ",
      target: "w-fit",
    },
    content: "",
  },
  modal: {
    content: {
      inner: "relative rounded-lg bg-white shadow",
    },
    header: {
      base: "flex items-start justify-between rounded-t px-5 pt-5",
    },
  },
  navbar: {
    root: {
      base: "fixed z-30 bg-white border-b border-gray-200 ",
      inner: {
        base: "flex gap-[10px] flex-wrap items-center justify-between "
      }
    },
    collapse: {
      base: "w-full lgPlus:w-auto lgPlus:hidden ",
      list: "mt-4 flex flex-col lgPlus:mt-0 lgPlus:flex-row lgPlus:space-x-8 lgPlus:text-sm lgPlus:font-medium",
      hidden: {
        on: "hidden",
        off: ""
      }
    },
    toggle: {
      base: "inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 lgPlus:hidden",
      icon: "h-6 w-6 shrink-0"
    }
  },
  sidebar: {
    root: {
      base: "flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-[85px] h-full duration-75 border-r border-gray-200 lg:flex transition-width",
      inner: "bg-white h-full"
    }
  }
  ,
  textarea: {
    base: "block w-full text-sm p-4 rounded-lg border disabled:cursor-not-allowed disabled:opacity-50",
  },
  toggleSwitch: {
    toggle: {
      checked: {
        off: "!border-gray-200 !bg-gray-200",
      },
    },
  },
  textInput: {
    base: "text-grayText text-[26px] leading-[24px] font-normal"
  },
  accordion: {
    root: {
      base: "divide-y divide-gray-200 bg-white",
      flush: {
        off: "rounded-lg ",
        on: ""
      }
    },
    content: {
      "base": "p-5 first:rounded-t-lg last:rounded-b-lg "
    },
    title: {
      arrow: {
        base: "h-6 w-6 shrink-0",
        open: {
          off: "",
          on: "rotate-180"
        }
      },
      flush: {
        off: "",
        on: ""
      },
      heading: "",
      open: {
        off: "",
        on: ""
      }
    }
  }
};

export default flowbiteTheme;
