import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./header/authHeader";

export const FAQApi = createApi({
    reducerPath: "FAQApi",
    baseQuery: fetchBaseQuery(authHeader()),
    tagTypes: ["FAQ"],

    endpoints: (builder) => ({
        createFAQ: builder.mutation({
            query: (data: any) => ({
                url: "faqs",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["FAQ"],

        }),
        getFAQ: builder.query({
            query: ({ id }) => `faqs?request_id=${id}`,
            transformResponse: (response: any) => response.data,
            providesTags: ["FAQ"]
        }),
        UpdateFAQ: builder.mutation({
            query: ({ id, data }: { id: any; data: any }) => ({
                url: `faqs/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["FAQ"],
        }),

    }),
});

export const {
    useCreateFAQMutation,
    useGetFAQQuery,
    useUpdateFAQMutation
} = FAQApi;
