import Cookies from "js-cookie";

export const categoriesHeader = (): any => {
  return {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers: Headers) => {
      const token = Cookies.get("access_token");
      const guest_UUID = Cookies.get("guest_uuid");
      const storedLanguage = localStorage.getItem("selectedLanguage");
      const language = storedLanguage ? JSON.parse(storedLanguage) : null;

      const effectiveLanguage = language?.language_code || 'en';
      if (token) {
        headers.set("language", `${effectiveLanguage}`);
        headers.set("Authorization", `Bearer ${token}`);
      } else if (guest_UUID && guest_UUID !== "undefined" && guest_UUID !== "") {
        headers.set("Authorization", `Bearer ${guest_UUID}`);
        headers.set("language", `${effectiveLanguage}`);
      }
      return headers;
    },
  };
};
