import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { multiLanguageHeader } from "./header/multiLanguageHeader";

// Building api for authentication
export const multiLanguageApi = createApi({
  reducerPath: "multiLanguageApi",
  baseQuery: fetchBaseQuery(multiLanguageHeader()),
  endpoints: (builder) => ({
    getMultiLanguage: builder.query({
      query: () => ({
        url: "multi-language",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetMultiLanguageQuery
} = multiLanguageApi;
