import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./header/authHeader";

export const dashBoardRequest = createApi({
    reducerPath: "dashBoardRequest",
    baseQuery: fetchBaseQuery(authHeader()),
    endpoints: (builder) => ({
        getDashBoard: builder.query({
            query: () => ({
                url: "user/dashboard",
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetDashBoardQuery
} = dashBoardRequest;
