import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { authHeader } from "./authHeader";
import { categoriesHeader } from "./header/categoriesHeader";
import { IApiResponse, ICategoryDetailResponse, ICategoryResponse, ICompaniesResponse, INewRequestResponse, IQuestionCategoryResponse, IQuestionDetailResponse } from "../type";

export const categories = createApi({
    baseQuery: fetchBaseQuery(categoriesHeader()),
    tagTypes: ["company"],
    endpoints: (builder) => ({
        getCategories: builder.query<ICategoryResponse, void>({
            query: () => "/categories/all",
        }),
        getCategoryById: builder.query({
            query: (id) => `/categories/${id}`,
            transformResponse: (response: ICategoryDetailResponse) => response.data
        }),
        getQuestionCategoryById: builder.query({
            query: (id) => `question/catagory/${id}`,
            transformResponse: (response: IQuestionCategoryResponse) => response.data
        }),
        getQuestionDetailByID: builder.query({
            query: (id) => `/question/${id}`,
            transformResponse: (response: IQuestionDetailResponse) => response.data
        }),
        getAllProjects: builder.query({
            query: () => `/projects`,
            transformResponse: (response: IApiResponse) => response.data
        }),
        getAllCompanies: builder.query({
            query: () => `/companies`,
            providesTags: ["company"],
            transformResponse: (response: ICompaniesResponse) => response.companies
        }),
        createCompany: builder.mutation({
            query: (company) => ({
                url: `/companies`,
                method: 'POST',
                body: company,
            }),
            invalidatesTags: ["company"],

            transformResponse: (response: any) => response.company,
        }),
        createRequest: builder.mutation({
            query: (request) => ({
                url: `/request`,
                method: 'POST',
                body: request,
            }),
            transformResponse: (response: INewRequestResponse) => response.data,
        }),
        answerQuestionV2: builder.mutation({
            query: (request) => ({
                url: `/answers/answer-question`,
                method: 'POST',
                body: request,
            }),
        }),
        answerQuestion: builder.mutation({
            query: (data) => {
                const formData = new FormData();
                if (data.file) formData.append('file', data.file);
                formData.append('request_id', data.request_id);
                if (data.answer_id) formData.append('answer_id', data.answer_id);
                if (data.answer) formData.append('answer', data.answer);
                if (data.question_id) formData.append('question_id', data.question_id);
                formData.append('question_type', data.question_type);

                return {
                    url: `/answers/answer-question`,
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        updateRequestStatusForGuest: builder.mutation({
            query: (data) => ({
                url: `/request/status/guest`,
                method: 'PATCH',
                body: data,
            }),
        }),
        updateRequestStatus: builder.mutation({
            query: (id) => ({
                url: `/request/${id}/status`,
                method: 'PATCH',
            }),
        }),
    }),
});

export const {
    useGetCategoriesQuery,
    useGetCategoryByIdQuery,
    useGetQuestionCategoryByIdQuery,
    useGetQuestionDetailByIDQuery,
    useGetAllProjectsQuery,
    useGetAllCompaniesQuery,
    useCreateCompanyMutation,
    useCreateRequestMutation,
    useAnswerQuestionMutation,
    useAnswerQuestionV2Mutation,
    useUpdateRequestStatusForGuestMutation,
    useUpdateRequestStatusMutation
} = categories;
