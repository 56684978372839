import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./header/authHeader";
import { ICompaniesV2Response } from "../type";

export const teamApi = createApi({
    reducerPath: "teamApi",
    baseQuery: fetchBaseQuery(authHeader()),
    tagTypes: ["team", "team-detail"],

    endpoints: (builder) => ({
        createTeam: builder.mutation({
            query: (data: any) => ({
                url: "team",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["team"],
        }),
        deleteTeam: builder.mutation({
            query: (id: number) => ({
                url: `team/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["team"],
        }),
        getCompanies: builder.query<ICompaniesV2Response, void>({
            query: () => ({
                url: 'companies',
                method: "GET",
            }),
        }),

        getAllUserUnassign: builder.query({
            query: () => ({
                url: 'user/unassigned',
                method: "GET",
            }),
            providesTags: ["team"],
            transformResponse: (response: any) => response?.data
        }),
        getUserByRequest: builder.query({
            query: (id) => ({
                url: `team/user-by-request/user?request_id=${id}`,
                method: "GET",
            }),
            providesTags: ["team"],
            transformResponse: (response: any) => response?.data
        }),
        getTeamsByRoleType: builder.query({
            query: (roleType: string) => ({
                url: `team?role_type=${roleType}`,
                method: "GET",
            }),
            providesTags: ["team"],
            transformResponse: (response: any) => response?.data,
        }),
        getUserByRoleType: builder.query({
            query: (roleType: string) => ({
                url: `team/user/type?role_type=${roleType}`,
                method: "GET",
            }),
            providesTags: ["team"],
            transformResponse: (response: any) => response?.data,
        }),
        addUser: builder.mutation({
            query: (data: any) => ({
                url: "team/add-users",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["team"],
        }),
        getTeamDetail: builder.query({
            query: (id) => ({
                url: `team/${id}`,
                method: "GET",
            }),
            providesTags: ["team"],
            transformResponse: (response: any) => response?.data,
        }),
        deleteUserFromTeam: builder.mutation({
            query: (data: any) => ({
                url: 'team/remove-user',
                method: 'DELETE',
                body: data,
            }),
            invalidatesTags: ["team"],
        }),
        getCompanyRquest: builder.query({
            query: () => ({
                url: 'companies/request',
                method: "GET",
            }),
            transformResponse: (response: any) => response?.data,
        }),
        getCompanyOffer: builder.query({
            query: () => ({
                url: 'companies/offers',
                method: "GET",
            }),
            transformResponse: (response: any) => response?.data,
        }),
        getOfferorTeamDetail: builder.query({
            query: (id) => ({
                url: `incoming-request/${id}/incoming-offer-team-details`,
                method: "GET",
            }),
            transformResponse: (response: any) => response?.data,
        }),
        inviteUserGuestToRequestV2: builder.mutation({
            query: (data: any) => ({
                url: "team/invite-guest-to-offer-team",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["team"],
        }),
        addExistingUserToOfferTeam: builder.mutation({
            query: (data: any) => ({
                url: "team/add-existing-user-to-offer-team",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["team"],
        }),
    }),
});

export const {
    useCreateTeamMutation,
    useDeleteTeamMutation,
    useGetTeamDetailQuery,
    useGetCompaniesQuery,
    useGetAllUserUnassignQuery,
    useGetUserByRequestQuery,
    useGetTeamsByRoleTypeQuery,
    useGetUserByRoleTypeQuery,
    useAddUserMutation,
    useDeleteUserFromTeamMutation,
    useGetCompanyRquestQuery,
    useGetCompanyOfferQuery,
    useGetOfferorTeamDetailQuery,
    useInviteUserGuestToRequestV2Mutation,
    useAddExistingUserToOfferTeamMutation
} = teamApi;
