import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { guestHeader } from "./header/guestHeader";

export const chatGuestUserApi = createApi({
    reducerPath: "chatGuestUserApi",
    baseQuery: fetchBaseQuery(guestHeader(window.location.search.includes('token=')
        ? new URLSearchParams(window.location.search).get('token')
        : null)),
    tagTypes: ["message-guest"],
    endpoints: (builder) => ({
        getMessageByRequestIdAndConverstationId: builder.query({
            query: ({ requestId, company_id }) => ({
                url: `message/by-request-and-company?request_id=${requestId}&company_id=${company_id}`,
                method: "GET",
            }),
            providesTags: ["message-guest"],
            transformResponse: (response: any) => response.data,
        }),
        sendMessageFromGuest: builder.mutation({
            query: (messageData) => ({
                url: "message/send-by-guest",
                method: "POST",
                body: messageData,
            }),
            invalidatesTags: ["message-guest"],
        }),
    }),
});

export const {
    useGetMessageByRequestIdAndConverstationIdQuery,
    useSendMessageFromGuestMutation,
} = chatGuestUserApi;
